const home = "/";
const signup = "/signup";
const signin = "/signin";
const comments = "/comments/:clothId";
const profile = "/profile/:username";
const editProfile = "/profile/edit";
const orders = "/orders";
const makeOrder= "/orders/:clothId";
const allorders = "/all_orders"
const explore = "/explore"
const emailConfirmation= "/confirmtion"
const addProduct = "/add_product";
const sellerProducts = "/my_products";
const editProduct = `/edit-product/:productId`;
const terms_and_conditions = '/terms_and_conditions';

export const ROUTES = {
  home: home,
  signUp: signup,
  signIn: signin,
  clothComments: comments,
  profile: profile,
  editProfile: editProfile,
  orders: orders,
  makeOrder: makeOrder,
  allorders: allorders,
  explore:explore,
  emailConfirmation:emailConfirmation,
  addProduct: addProduct,
  sellerProducts: sellerProducts,
  editProduct: editProduct,
  terms_and_conditions: terms_and_conditions
};
