import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { AccountCircle, Home } from "@mui/icons-material";
import Styles from "../Assets/Styles/Header.module.css";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ExploreIcon from '@mui/icons-material/Explore';

function Header({ userData, isLoggedIn }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleIconClick = (path) => {
    navigate(path); // Navigate to the specified path
    if (location.pathname === path) {
      window.location.reload(); // Reload only if on the specified path

    }
  };

  return (
    <header className={`${Styles.header} ${isLoggedIn ? "loggedIn" : ""}`}>
      <div className="container">
        <div className={`row ${Styles.navIcons}`}>
          <div className="col-2 col-md-2">
            <Link to="/" className={Styles.iconLink} onClick={() => handleIconClick("/")}>
              <Home className={Styles.icon} />
              <p>Home</p>
            </Link>
          </div>
          <div className="col-2 col-md-2">
            <Link to="/explore" className={Styles.iconLink} onClick={() => handleIconClick("/explore")}>
              <ExploreIcon className={Styles.icon} />
              <p>Explore</p>
            </Link>
          </div>
          {isLoggedIn && (
            <div className="col-2 col-md-2">
              <Link to="/all_orders" className={Styles.iconLink} onClick={() => handleIconClick("/all_orders")}>
                <ReceiptLongIcon className={Styles.icon} />
                <p>Orders</p>
              </Link>
            </div>
          )}
          {isLoggedIn && (
            <div className="col-2 col-md-2">
              <div className={`${Styles.authLink}`}>
                <Link to={`/profile/${userData.username}`} className={Styles.iconLink} style={{ marginRight: "10px" }} onClick={() => handleIconClick(`/profile/${userData.username}`)}>
                  <div
                    className=""
                    style={{
                      borderRadius: "50%",
                      cursor: "pointer",
                      border: "1px solid goldenrod",
                      width: "30px",
                      height: "30px",
                      minWidth: "30px",
                      minHeight: "30px",
                    }}
                  >
                    {userData.profile_picture ? (
                      <Image
                        src={userData.profile_picture}
                        alt={userData.username}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <AccountCircle
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        className={Styles.icon}
                      />
                    )}
                  </div>
                  <p>{userData.username}</p>
                </Link>
              </div>
            </div>
          )}
          {!isLoggedIn && (
            <div className="col-2 col-md-2">
              <Link to="/signin" className={Styles.iconLink} onClick={() => handleIconClick("/signin")}>
                <AccountCircle className={Styles.icon} />
                <p>Sign in</p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
