import CryptoJS from 'crypto-js';

const EncryptDecryptComponent = ({ data, isEncrypt }) => {
  const secretKey = 'levick_twenty3$#65108875*';

  const encryptData = (data) => {
    // Ensure data is a string for encryption
    return CryptoJS.AES.encrypt(String(data), secretKey).toString();
  };

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
    
  };

  const processData = () => {
    if (isEncrypt) {
      return encryptData(data);
    } else {
      return decryptData(data);
    }
  };

  return processData();
};

export default EncryptDecryptComponent;
