import React, { useState, useEffect, Suspense, lazy } from 'react';
import { CloudinaryContext } from 'cloudinary-react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SpeedInsights } from '@vercel/speed-insights/react';
import ContactPage from './Components/ContactPage';
import LoadingSpinner from './Shared/LoadingSpinner';
import EncryptDecryptComponent from './Auth/EncryptDecryptComponent';
import { ROUTES } from './Routes/Routes';
import Header from "./Components/Header";
import "./App.module.css"

const Home = lazy(() => import('./Components/Home'));
const SignUpForm = lazy(() => import('./Auth/Signup'));
const SignInForm = lazy(() => import('./Auth/Signin'));
const CommentHandler = lazy(() => import('./Screens/CommentsHandler'));
const Profile = lazy(() => import('./Components/Profile'));
const EditProfile = lazy(() => import('./Screens/EditProfile'));
const OrderForm = lazy(() => import('./Screens/Order'));
const AllOrders = lazy(() => import('./Components/Orders'));
const Explore = lazy(() => import('./Components/StoryLine'));
const AddProduct = lazy(() => import('./Screens/AddProduct'));
const SellerProducts = lazy(() => import("./Screens/SellerProduct"));
const EditProduct = lazy(() => import('./Screens/EditProduct'));
const ModalScreen = lazy(() => import('./Shared/ModalScreen'));
const TermsAndConditions = lazy(() => import('./Shared/Terms_ and _Conditions'));

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState([]);
  const [clothes, setClothes] = useState([]);
  const [isSeller, setIsSeller] = useState(false);
  const [isBuyer, setIsBuyer] = useState(false);
  const location = useLocation(); // Get the current route

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const encryptedUserId = localStorage.getItem('request');
        if (encryptedUserId) {
          const userId = EncryptDecryptComponent({
            data: encryptedUserId,
            isEncrypt: false,
          });

          const response = await fetch(`https://levick-e86fc3b20f07.herokuapp.com/users/${userId}`);
          const data = await response.json();
          setUserData(data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchClothes = async () => {
      try {
        const response = await fetch('https://levick-e86fc3b20f07.herokuapp.com/cloths');
        const data = await response.json();
        setClothes(data);
      } catch (error) {
        console.error("Error fetching clothes data:", error);
      }
    };

    fetchClothes();
  }, []);

  useEffect(() => {
    const session = localStorage.getItem('session');
    if (session) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setIsSeller(userData.role === 'seller');
      setIsBuyer(userData.role === 'buyer');
    }
  }, [userData]);

  const shouldHideHeader = [ROUTES.signUp, ROUTES.signIn].includes(location.pathname);

  return (
    <div>
      <CloudinaryContext cloudName="djmvocl1y">
        <SpeedInsights />
        <Routes>
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path={ROUTES.home}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Home
                  userData={userData}
                  isLoggedIn={isLoggedIn}
                  clothes={clothes}
                />
              </Suspense>
            }
          />
          <Route
            path="/item/:id"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ModalScreen />
              </Suspense>
            }
          />
          <Route path={ROUTES.terms_and_conditions} element={<TermsAndConditions />} />
          <Route
            path={ROUTES.clothComments}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <CommentHandler
                  userData={userData}
                  isLoggedIn={isLoggedIn}
                  clothes={clothes}
                />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.profile}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Profile
                  userData={userData}
                  isLoggedIn={isLoggedIn}
                />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.editProfile}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <EditProfile
                  userData={userData}
                  isLoggedIn={isLoggedIn}
                />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.makeOrder}
            element={
              isLoggedIn && isBuyer ? (
                <Suspense fallback={<LoadingSpinner />}>
                  <OrderForm
                    userData={userData}
                    isLoggedIn={isLoggedIn}
                  />
                </Suspense>
              ) : (
                <Navigate to={ROUTES.home} />
              )
            }
          />
          <Route
            path={ROUTES.allorders}
            element={
              isLoggedIn ? (
                <Suspense fallback={<LoadingSpinner />}>
                  <AllOrders
                    userData={userData}
                    isLoggedIn={isLoggedIn}
                  />
                </Suspense>
              ) : (
                <Navigate to={ROUTES.home} />
              )
            }
          />
          <Route
            path={ROUTES.explore}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Explore
                  clothes={clothes}
                  userData={userData}
                  isLoggedIn={isLoggedIn}
                />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.addProduct}
            element={
              isLoggedIn && isSeller ? (
                <Suspense fallback={<LoadingSpinner />}>
                  <AddProduct userData={userData} isLoggedIn={isLoggedIn} />
                </Suspense>
              ) : (
                <Navigate to={ROUTES.home} />
              )
            }
          />
          <Route
            path={ROUTES.sellerProducts}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SellerProducts userData={userData} clothes={clothes} isLoggedIn={isLoggedIn} />
              </Suspense>
            }
          />
          <Route
            path={ROUTES.editProduct}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <EditProduct userData={userData} isLoggedIn={isLoggedIn} />
              </Suspense>
            }
          />
          {!isLoggedIn && (
            <>
              <Route
                path={ROUTES.signUp}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <SignUpForm />
                  </Suspense>
                }
              />
              <Route
                path={ROUTES.signIn}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <SignInForm />
                  </Suspense>
                }
              />
            </>
          )}
        </Routes>
      </CloudinaryContext>
      {!shouldHideHeader && <Header userData={userData} isLoggedIn={isLoggedIn} />}
    </div>
  );
}

export default App;
